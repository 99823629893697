<template>
  <div>
    <b-table
      :items="mesas"
      :fields="campos"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
    >
      <template #cell(actions)="row">
        <div>
          <b-btn variant="primary" size="sm" @click="editar(row.item)">
            <i class="fas fa-edit"></i> Editar</b-btn
          >
        </div>
      </template>
      <template #cell(status)="row">
        <div class="row justify-content-center">
          <b-checkbox
            switch
            v-model="row.item.situacao"
            :value="1"
            :unchecked-value="0"
            @change="salvar(row.item)"
          ></b-checkbox>
          {{ row.item.situacao == 1 ? row.item.status = "ABERTO" : row.item.status = "FECHADO" }}
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="mesas.length"
      :per-page="paginacao.registros_por_pagina"
    >
    </b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    mesas: { type: Array, requried: true },
  },
  data() {
    return {
      campos: [
        { key: "cod_mesa", label: "Código", sortable: true },
        { key: "mesa", label: "Mesa" },
        { key: "status", label: "Status" },

        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
    };
  },
  methods: {
    editar(mesa) {
      this.$emit("editar", mesa);
    },
    salvar(mesa){
      this.$emit('salvar', mesa)
    }
  },
};
</script>

<style></style>
