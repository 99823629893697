<template>
  <div class="container-fluid">
    <BarraCrud :botaoNovo="true" :onNovo="novo" />
    <MesaTable
      :mesas="mesas"
      @editar="editar($event)"
      @salvar="salvar($event)"
      ref="mesaTableComp"
    />
    <b-modal id="modal-mesa" content-class="prodModal" hide-footer>
      <template #modal-title>
        <i class="fas fa-plus"></i> Adicionar/Editar Mesa
      </template>

      <MesaForm :mesa_edit="mesa_edit" @salvar="salvar($event)" />
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "@/components/common/BarraCrud";
import MesaTable from "@/components/Mesa/MesaTable.vue";
import MesaForm from "@/components/Mesa/MesaForm.vue";
import MesaLib from "@/libs/MesaLib.js";

export default {
  components: {
    BarraCrud,
    MesaTable,
    MesaForm,
  },
  data() {
    return {
      mesas: [],
      mesa_edit: {},
      loading: false,
    };
  },
  methods: {
    async carregar() {
      await MesaLib.get()
        .then((data) => {
          // console.log(data);
          this.mesas = data.data;
        })
        .catch((err) => {
          console.log("Erro ao carregar mesas", err);
          this.$swal.fire({
            title: "Ops! Alguma coisa deu errado :(",
            icon: "error",
            toast: true,
            timer: 1500,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
    limpar() {
      for (const key in this.mesa_edit) {
        this.mesa_edit[key] = "";
      }
    },
    novo() {
      this.limpar()
      this.$bvModal.show("modal-mesa");
    },
    editar(mesa) {
      Object.assign(this.mesa_edit, mesa);
      this.$bvModal.show("modal-mesa");
    },
    async salvar(mesa) {
      // console.log(mesa);
      let result;
      try {
        this.loading = true;
        result = await MesaLib.store(mesa);
        if (result.data.success) {
          this.$swal.fire({
            toast: true,
            title: "Mesa salva com sucesso!",
            icon: "success",
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });
          this.carregar();
        }
      } catch (error) {
        console.log(`Erro ${error}`);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.carregar();
  },
};
</script>

<style></style>
