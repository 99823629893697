<template>
  <div>
    <b-form @submit.prevent.stop="salvar()">
      <div class="row">
        <div class="col-12 col-sm-9">
          <b-form-group>
            <label>Mesa</label>
            <b-form-input
              placeholder="Mesa"
              :state="validateState('mesa')"
              v-model="$v.mesa.mesa.$model"
            />
          </b-form-group>
        </div>

        <div class="col-12 col-sm-3">
          <b-form-group>
            <label>Status</label>
            <div class="row">
              <b-form-checkbox
                switch
                :state="validateState('situacao')"
                v-model="$v.mesa.situacao.$model"
                :unchecked-value="0"
                :value="1"
              />
              {{
                mesa.situacao == 1
                  ? (mesa.status = "ABERTO")
                  : (mesa.status = "FECHADO")
              }}
            </div>
          </b-form-group>
        </div>
      </div>
  
      <SelectEmpresaCaixa
        hide-caixa
        :empresa="mesa.cod_empresa"
        @empresa="mesa.cod_empresa = $event"
      />
      <div class="bottom-actions">
        <hr>
        <b-button
          variant="primary"
          class="mr-3"
          type="submit"
        >
          <i class="fas fa-save" /> Salvar
        </b-button>
        <b-button
          variant="danger"
          @click="fecharModal()"
        >
          <i class="fas fa-ban" /> Cancelar
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import SelectEmpresaCaixa from "@/components/common/SelectEmpresaCaixa.vue"
export default {
  mixins: [validationMixin],
  components:{
    SelectEmpresaCaixa
  },
  data() {
    return {
      mesa: {
        mesa: "",
        situacao: 0,
        status: "Fechado",
        cod_empresa: null
      },
      editando: false,
    };
  },
  validations: {
    mesa: {
      mesa: { required },
      situacao: {
        required,
      },
      status: {
        required,
      },
    },
  },
  props: {
    mesa_edit: { type: Object },
  },
  methods: {
    fecharModal() {
      this.$bvModal.hide("modal-mesa");
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.mesa[name];
      return $dirty ? !$error : null;
    },
    salvar() {
      this.$emit("salvar", this.mesa);
      if (!this.editando) {
        this.$bvModal.hide('modal-mesa')
      }
    },
  },
  mounted() {
    if (this.mesa_edit) {
      if (typeof this.mesa_edit.cod_mesa == "number") {
        Object.assign(this.mesa, this.mesa_edit);
        this.editando = true;
      }
    }
  },
};
</script>

<style></style>
